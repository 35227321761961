import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Heading from '$ui/heading';
import Input from '$ui/input';
import Button from '$ui/button';
import Container from '$ui/container';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, 'Password is too short')
    .required('Please provide your password'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'The two passwords do not match')
    .required('Please confirm your password')
});

const ResetPasswordPage = () => {
  const handleSubmit = useCallback(async values => {
    try {
      toast.success('Password reseted  successfully!');
      navigate('/');
    } catch (err) {
      toast.error(err.response?.data.message || 'Network Error');
    }
  }, []);

  return (
    <Container display='flex' className='bg-primary phone:px-4'>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={resetPasswordSchema}
        initialValues={{ password: '', confirmPassword: '' }}>
        {formik => (
          <form
            onSubmit={formik.handleSubmit}
            className='w-[25rem] py-10 px-10 bg-gray-100 space-y-5 rounded-xl shadow-2xl phone:w-4/5'>
            <Heading variant='tertiary'> reset your Password</Heading>

            <Input
              name='password'
              type='password'
              placeholder='Password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
            />

            <Input
              name='confirmPassword'
              type='password'
              placeholder='confirm Password'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />

            <Button
              variant='filled'
              type='submit'
              className='w-full mt-8'
              isLoading={formik.isSubmitting}>
              Reset password
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ResetPasswordPage;
